<template>
  <div class="EquipmentOperationManage">
	<!-- <headers :title="Header_title"></headers> -->
      <div :class="{'BackgroundBox':expansion, 'BackgroundBox2':!expansion}">
		  <div class="gaugeoutfit2">
		     <span class="xian"></span>
		     <span class="title">园区设备分布图</span>
		  </div>
        <div :class="{'ContainerDiv':expansion, 'ContainerDiv2':!expansion}">
          <div class="StateHead" v-if="showing">
            <div class="Iconnavigation">
              <div class="listicon">
                <img src="../../../static/images/icon1.png"/>
                <div class="testcolor1" style="color:#0cc0ff;">{{AllAmountNum}}</div>
                <div class="test2">设备总数</div>
              </div>
              <div class="listicon">
                <img src="../../../static/images/icon2.png"/>
                <div class="test1" style="color:#00d392;">{{RunningAmountNum}}</div>
                <div class="test2">运行设备</div>
              </div>
              <div class="listicon">
                <img src="../../../static/images/icon3.png"/>
                <div class="testcolor1" style="color:#ff720c;">{{StandByAmountNum}}</div>
                <div class="test2">待机设备</div>
              </div>
              <!-- <div class="listicon">
                <img src="../../../static/images/icon4.png"/>
                <div class="testcolor1" style="color:#d00032;">{{AlarmAmountNum}}</div>
                <div class="test2">报警设备</div>
              </div> -->
			  <div class="listicon">
			    <img src="../../../static/images/icon6.png"/>
			    <div class="testcolor1" style="color:#cccccc;">{{ShutDownAmountNum}}</div>
			    <div class="test2">停机设备</div>
			  </div>
			  <div class="listicon">
			    <img src="../../../static/images/icon5.png"/>
			    <div class="testcolor1" style="color:#ecc928;">{{BadAmountNum}}</div>
			    <div class="test2">故障设备</div>
			  </div>
            </div>
          </div>
          <div class="popup"
             style="position:relative;">
             <div class="list">
                <p>{{DivisionName}}</p>
                <p>设备总数 ：{{DeviceTotal}} <span>台</span></p>
                  <div class="state"><span class="normal"></span><span style="color:#fff;">运行 ：{{DeviceRun}}</span></div>
                  <div class="state"><span class="unusual"></span><span style="color:#fff;">待机 ：{{DeviceStandby}}</span></div>
                  <div class="state"><span class="notrunning"></span><span style="color:#fff;">报警 ：{{DevicePolice}}</span></div>
				  <div class="state"><span class="DeviceFault"></span><span style="color:#fff;">故障 ：{{DeviceFault}}</span></div>
				  <div class="state"><span class="DeviceHalt"></span><span style="color:#fff;">停机 ：{{DeviceHalt}}</span></div>
             </div>
           </div>
          <div v-loading="loading"
             element-loading-text="加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(1,34,48, 0)" id="textDivMz" :class="{'textDivMz-expansion':expansion, 'textDivMz':!expansion}">
            <canvas id="canvas" :class="{'canvas-expansion':expansion, 'canvas':!expansion}"></canvas>
            <canvas id="canvas2" :class="{'canvas2-expansion':expansion, 'canvas2':!expansion}"></canvas>
            <canvas id="canvas3" :class="{'canvas3-expansion':expansion, 'canvas3':!expansion}"></canvas>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  import {
    formatDate,
    getDayOfNextYear,
    getNextMonth,
    addDate
  } from '@/common/js/date.js'
  import {isEmpty,convertImg} from '@/common/js/base.js'
  import $ from 'jquery'
  import { GetBGImage,GetAgencyEquipJsonData,GetEquipmentInfoInLocation } from "@/api/user"; //页面接口
  import { GetEquipmentBoard} from "@/api/ProductionManagementKanban"; //页面接口
  import {mapGetters,mapMutations} from 'vuex'
  // import headers from '@/components/header/headermain.vue'

  import empty from '@/assets/icons/empty02.png'
  import error from '@/assets/icons/error.png'

  export default {
    components: {
		// headers
    },
    data() {
      return {
		Header_title:'设备运行监控',
        polygonPoints: [],

        empty:empty,
        error:error,

        color1:"#ffffff",
        color2:"#FF00FF",

        loading:false,
        emptyMsg:false,
        errorMsg:false,
        noAuthMsg:false,

        canvas:null,
        canvas2:null,
        canvas3:null,
        canvas3:null,

        ctx:null,
        ctx2:null,
        ctx3:null,
        ctx4:null,

        locationId:this.$route.params.locationId,
        Transformers:[],
        Meterinfo:[],
        bgurl:null,
        ChartData:[],

        DivisionName:'',
		DeviceTotal:'',
		DeviceRun:'',
		DeviceStandby:'',
		DevicePolice:'', 
		DeviceFault:'',
		DeviceHalt:'',

        // normal:'',
        // unusual:'',
        // notrunning:'',

        RunningAmount:'',
        TroubleAmount:'',
        StopAmount:'',

        AlarmAmountNum:'',
        AllAmountNum:'',
        BadAmountNum:'',
        RunningAmountNum:'',
        ShutDownAmountNum:'',
        StandByAmountNum:'',
        showing:false,
        driverlist:[],
      }
    },
    computed:{
      ...mapGetters(['expansion'])
    },
    created(){
		
    },
    watch: {
      '$route'(to, from){
        if(to.path == '/EquipmentOperationManage'){
          setTimeout(()=>{
            this.init();
            this.initPopup();
          },1000)
        }
      },
      expansion(v){
        if(this.$route.path == '/EquipmentOperationManage'){
          setTimeout(()=>{
            this.drawAllImage();
          },1000)
          if($(".popup")){
            $(".popup").hide();
          }
        }
      },
      bgurl(val){
        if(this.bgurl!=''){
          this.ArrayDate();
          this.EquipmentOverviewNumber();
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
          this.init();
          //生产，演示背景图
          // $(".canvas-expansion").css("background-image","url()");
          // $(".canvas").css("background-image","url()");
      })
      window.addEventListener('resize', ()=>{
        if(this.$route.path == '/EquipmentOperationManage'){
          this.setExpansion(true);
          setTimeout(()=>{
           this.init();
           this.ArrayDate();
          },1000)
        }
      });
    },
    methods: {
      ...mapMutations(['setExpansion']),
      drawAllImage(){
        if(this.ctx){
          this.ctx.clearRect(0, 0, convertImg(this.canvas.width)+convertImg(800), convertImg(this.canvas.height)+convertImg(500));
        }
        if(this.ctx2){
          this.ctx2.clearRect(0, 0, convertImg(this.canvas.width)+convertImg(800), convertImg(this.canvas.height)+convertImg(500));
        }
        if(this.ctx3){
          this.ctx3.clearRect(0, 0, convertImg(this.canvas.width)+convertImg(800), convertImg(this.canvas.height)+convertImg(500));
        }
        if(this.ctx4){
          this.ctx4.clearRect(0, 0, convertImg(this.canvas.width)+convertImg(800), convertImg(this.canvas.height)+convertImg(500));
        }

        $("#textDivMz div[id^='mz']").remove();//清空文字
        for(let i=0; i<this.polygonPoints.length; i++){//绘制所有图形
          this.polygon(this.polygonPoints[i].points, this.ctx2, this.expansion);
          this.text(this.polygonPoints[i].name, this.polygonPoints[i].locationId, this.polygonPoints[i].points,i,this.ChartData);
        }
      },
      init(){
		this.loading = true;
        GetBGImage({
            agencyId:sessionStorage.getItem('agencyId')
          }).then(res => {
            let blob = new Blob([res]);
            let url = window.URL.createObjectURL(blob);
            this.bgurl = url;
             // sessionStorage.DeviceManagementBg = this.bgurl;
            localStorage.setItem("DeviceManagementBg",this.bgurl)
            //生产，演示背景图
            $(".canvas-expansion").css("background-image","url("+this.bgurl+")");
            $(".canvas").css("background-image","url("+this.bgurl+")");
			this.loading = false;
        	//
        }).catch(err => {
        
        })
        this.initImage();
      },
      ArrayDate(){
        this.emptyMsg = false;
        this.errorMsg = false;
        this.noAuthMsg = false;
        this.loading = true;
        this.polygonPoints = [];
        GetAgencyEquipJsonData({
        	AgencyId:sessionStorage.getItem('agencyId')
        }).then(res => {
           let obj = res.Content;
           this.polygonPoints = JSON.parse(obj).Content.polygonPoints;
           localStorage.setItem("polygonPoints",JSON.stringify(this.polygonPoints));
           if(res.Code==-1){
             this.polygonPoints = [];
             this.errorMsg = true;
             this.loading = false;
             this.$notify.info({
               title: '消息',
               message: '获取设备信息失败'
             });
             return;
           }else if(isEmpty(obj)){
             this.polygonPoints = [];
             this.$notify.info({
               title: '消息',
               message:'暂无数据'
             });
             this.loading = false;
             this.emptyMsg = true;
             return;
           }
           this.loading = false;
        }).then(res => {
        	this.CanvasDate();
        }).catch(err => {
        	this.polygonPoints = [];
        	if(err.status == 403){//没有权限
        	  this.noAuthMsg = true;
        	}else{
        	  this.errorMsg = true;
        	}
        	this.loading = false;
        })
      },
      EquipmentOverviewNumber(){
        this.showing = false;
        GetEquipmentBoard({
        	AgencyId:sessionStorage.getItem('agencyId')
        }).then(res => {
          let obj = res.Content.EquipmentAmount;
		  this.AlarmAmountNum = obj.AlarmAmount;
		  this.AllAmountNum = obj.AllAmount;
		  this.BadAmountNum = obj.BadAmount;
		  this.RunningAmountNum = obj.RunningAmount;
		  this.ShutDownAmountNum = obj.ShutDownAmount;
		  this.StandByAmountNum = obj.StandByAmount;
		  
          this.showing = true;
        }).catch(err => {
        
        })
      },
      CanvasDate(){
        GetEquipmentInfoInLocation({
        	AgencyId:sessionStorage.getItem('agencyId')
        }).then(res => {
         let obj = res.Content;
         for (var i = 0;i < obj.length; i++){
           if(obj[i].AllAmount == 0){
              obj.splice(i, 1);
              i--;
           }
         }
          this.ChartData = obj;
         if(res.Code==-1){
           this.ChartData = [];
           this.$notify.info({
             title:'消息',
             message: '获取数据失败'
           });
           return;
         }else if(isEmpty(obj)){
           this.ChartData = [];
           this.$notify.info({
             title:'消息',
             message: '暂无数据'
           });
           return;
         }
         this.drawAllImage();
         this.initPopup();
        }).catch(err => {
			this.ChartData = [];
        })
      },
      initPopup(){
        for(let i=0; i<this.polygonPoints.length; i++){
          if(this.polygonPoints[i].locationId == this.locationId){
            this.polygon(this.polygonPoints[i].points, this.ctx3, this.expansion);
            if(convertImg(Number(this.polygonPoints[i].points[0][0]))<(window.innerWidth/2)){
              $(".popup").show().css({"left":convertImg(Number(this.polygonPoints[i].points[0][0]))+convertImg(10),"top":convertImg(100)});
            }else{
              $(".popup").show().css({"left":convertImg(Number(this.polygonPoints[i].points[0][0]))-convertImg(600),"top":convertImg(100)});
            }
            // try{
            //   this.getTransformers();
            // }catch(e){
            //   console.log(e);
            // }
            break;
          }else if(i == this.polygonPoints.length-1){
            // this.$notify.info({
            //   title: this.$t('common.消息'),
            //   message: this.$t('DeviceMonitor.没找到对应厂房')
            // });
          }
        }
      },
      //地图
      initImage(){
          this.canvas = document.getElementById("canvas");//绘制背景图和mask
          this.canvas.width = $("#canvas").width();
          this.canvas.height = $("#canvas").height();

          this.canvas2 = document.getElementById("canvas2");//绘制矩形
          this.canvas2.width = $("#canvas2").width();
          this.canvas2.height = $("#canvas2").height();

          this.canvas3 = document.getElementById("canvas3");//绘制鼠标点击矩形
          this.canvas3.width = $("#canvas3").width();
          this.canvas3.height = $("#canvas3").height();



          if(this.canvas.getContext && this.canvas2.getContext && this.canvas3.getContext){
            this.ctx = this.canvas.getContext("2d");
            this.ctx.fillStyle = "rgba(4,152,78,0.6)"
            this.ctx.strokeStyle = "#ffffff";
            this.ctx.lineWidth = 1;

            this.ctx2 = this.canvas2.getContext("2d");
            this.ctx2.fillStyle = "rgba(4,152,78,0.6)"
            this.ctx2.strokeStyle = "#ffffff";
            this.ctx2.lineWidth = 1;

            this.ctx3 = this.canvas3.getContext("2d");
            this.ctx3.fillStyle = "rgba(255,255,0,0.3)"
            this.ctx3.strokeStyle = "#ffffff";
            this.ctx3.lineWidth = 1;

              this.canvas3.onmousemove = (e)=> {
                e = e || event;
                if(this.expansion){//菜单展开
                  var x = e.clientX - convertImg(this.canvas.offsetLeft)-convertImg(70);
                  var y = e.clientY - convertImg(this.canvas.offsetTop)-convertImg(30);
                }else{
                  var x = e.clientX - convertImg(this.canvas.offsetLeft)-convertImg(40);
                  var y = e.clientY - convertImg(this.canvas.offsetTop)-convertImg(40);
                }
                // this.mask(x,y, this.ctx, this.expansion);
              }
              this.canvas3.onclick = (e)=> {
                $(".popup").hide();
                // console.log((e.clientX-45)+","+(e.clientY-25));
              }
          }
      },
      // mask(cx, cy, ctx, expansion) {
      //   ctx.clearRect(0, 0, convertImg(this.canvas.width)+ convertImg(800), convertImg(this.canvas.height)+ convertImg(500));
      //   var image = new Image();
      //   image.src = this.bgurl;

      //   ctx.save();
      //   ctx.beginPath();
      // 	ctx.fillStyle = 'rgba(0,0,0,0.3)';
      // 	ctx.fillRect(0, 0, convertImg(this.canvas.width)+ convertImg(800), convertImg(this.canvas.height)+ convertImg(500));
      // 	ctx.fill();
      // 	ctx.save();

      // 	ctx.beginPath();
      //   //var radial = ctx.createRadialGradient(cx,cy,0,cx,cy,radius);
      // 	//radial.addColorStop(0,'rgba(0,0,0,1)');
      // 	//radial.addColorStop(0.6,'rgba(0,0,0,1)');
      // 	//radial.addColorStop(1,'rgba(0,0,0,0)');
      //   ctx.fillStyle="rgba(255,255,255,0)";

      //   ctx.arc(cx-convertImg(200), cy-convertImg(170), convertImg(120), 0, Math.PI * 2, false);

      // 	ctx.fill();
      // 	// 将上面的区域作为剪辑区域
      //   ctx.clip();

      // 	// 由于使用clip()，画布背景图片会出现在clip()区域内
      //   if(document.body.clientWidth<=1024){//150%
      //     ctx.drawImage(image, 0, 0, 3000, 1700,0,0, convertImg(1310), convertImg(655));
      //   }else if(document.body.clientWidth>=4608){//25%
      //     ctx.drawImage(image, 0, 0, 3000, 1700,0,0, convertImg(1341), convertImg(665));
      //   }else if(document.body.clientWidth>=3072){//50%
      //     ctx.drawImage(image, 0, 0, 3000, 1700,0,0, convertImg(1335), convertImg(665));
      //   }else if(document.body.clientWidth>=2048){//75%
      //     ctx.drawImage(image, 0, 0, 3000, 1700,0,0, convertImg(1335), convertImg(667));
      //   }else{//100%
      //     ctx.drawImage(image, 0, 0, 3000, 1700,0,0, convertImg(1344), convertImg(667));
      //   }

      // 	ctx.restore();
      // },
      polygon(poly, context, expansion) {
        // ctx.clearRect(0, 0, convertImg(this.canvas.width+100), convertImg(this.canvas.height+100));
        context.beginPath();
        if(document.body.clientWidth<=1024){
          context.moveTo(convertImg(poly[0][0])-convertImg(180), convertImg(poly[0][1])-convertImg(55));
        }else if(document.body.clientWidth>=3072){
          context.moveTo(convertImg(poly[0][0])-convertImg(160), convertImg(poly[0][1])-convertImg(52));
        }else{
          context.moveTo(convertImg(poly[0][0])-convertImg(162), convertImg(poly[0][1])-convertImg(52));
        }
        for (var i = 1; i < poly.length; i++) {
          if(document.body.clientWidth<=1024){
            context.lineTo(convertImg(poly[i][0])-convertImg(180), convertImg(poly[i][1])-convertImg(55));
          }else if(document.body.clientWidth>=3072){
            context.lineTo(convertImg(poly[i][0])-convertImg(160), convertImg(poly[i][1])-convertImg(52));
          }else{
            context.lineTo(convertImg(poly[i][0])-convertImg(162), convertImg(poly[i][1])-convertImg(52));
          }
        }
        context.closePath();
        context.fill();
        context.stroke();
      },
      Drawdesigns(px,py,locationId,index,ChartData){
           let data=[];
           let canvas = document.getElementById("mz"+locationId);
           let ctx = canvas.getContext('2d');
           //总数TotalAmount，正常，未运行，故障
           for(let j=0;j<ChartData.length;j++){
             if(ChartData[j].LocationId == locationId){
                data.push((ChartData[j].RunningAmount/ChartData[j].AllAmount)*100,(ChartData[j].StandByAmount/ChartData[j].AllAmount)*100,(ChartData[j].AlarmAmount/ChartData[j].AllAmount)*100,(ChartData[j].BadAmount/ChartData[j].AllAmount)*100,(ChartData[j].ShutDownAmount/ChartData[j].AllAmount)*100)
             }
           }
           //
           var img=new Image();
		   const Logo = require("../../../static/images/canvas.png");
           img.onload = function(){
               // 将图片画到canvas上面上去！
               ctx.drawImage(img,24,36,90,90);
           }
		    img.src=Logo;
			var color = ["#1dac5d", "#ff9600", "#f14848","#ff715b", "#cccccc"]
			for(var i=0;i<data.length;i++){
				if(data[i]==0){
					color[i] = 'transparent';
				}
			}   
		   // console.log(color,'color')
		   // console.log(data,'data')
           let ring = new Ring("50", "15", "#fefefe", color, "round");
           ring.drawRing(ctx, 2 * Math.PI / 5, data,70,80);//占据的百分比分别是20%，50%，30%
      },
      text(name, locationId, poly,index,ChartData) {
        let px = convertImg(poly[0][0])+((convertImg(poly[2][0]) - convertImg(poly[0][0]))/2)-convertImg(20);
        var py = convertImg(poly[2][1])+((convertImg(poly[0][1]) - convertImg(poly[2][1]))/2)-convertImg(20);
        for(let j=0;j<ChartData.length;j++){
          if(ChartData[j].LocationId == locationId){
            $("<canvas/>",{
            	id: "mz"+locationId,
            	name: name,//
            	width:convertImg(85),
            	height:convertImg(43),
            }).appendTo($("#textDivMz"));
            //覆盖鼠标滑过及点击事件
            $("<div/>",{
            	id: "cover"+locationId,
            	width:convertImg(48),
            	height:convertImg(48),
            	hover: (e)=> {
                //13#厂房顶点坐标 左下角顺时针: 733 285, 703 265, 896 225, 983 290, 822 273
                if(this.expansion){//菜单展开
                  var x = e.clientX - convertImg(this.canvas.offsetLeft)-convertImg(20);
                  var y = e.clientY - convertImg(this.canvas.offsetTop)-convertImg(0);
                }else{
                  var x = e.clientX - convertImg(this.canvas.offsetLeft)-convertImg(-100);
                  var y = e.clientY - convertImg(this.canvas.offsetTop)-convertImg(0);
                }

                //画点击后的背景
                for(let i=0; i<this.polygonPoints.length; i++){
                  let is = this.polygonPoints[i].name == name;
                  if(is){
                    this.locationId = this.polygonPoints[i].locationId;
                    this.ctx3.clearRect(0, 0, convertImg(this.canvas3.width)+convertImg(800), convertImg(this.canvas3.height)+convertImg(500));
                    this.polygon(this.polygonPoints[i].points, this.ctx3, this.expansion);
                    break;
                  }
                }
                //点击饼图显示数据
                 this.DivisionName = name;
				 this.DeviceTotal = ChartData[j].AllAmount;
				 this.DeviceRun = ChartData[j].RunningAmount;
				 this.DeviceStandby = ChartData[j].StandByAmount;
				 this.DevicePolice = ChartData[j].AlarmAmount; 
				 this.DeviceFault = ChartData[j].BadAmount;
				 this.DeviceHalt = ChartData[j].ShutDownAmount
				 
                
                if(x<window.innerWidth/2){
                  $(".popup").show().css({"left":x+convertImg(10),"top":convertImg(100)});
                }else{
                  $(".popup").show().css({"left":x-convertImg(600),"top":convertImg(100)});
                }
				
            	},
              mouseout:function(){
                $(".popup").hide();
              },
              click:(e)=> {
                // this.$router.push({path:"/MonitoringDetails", query:{'locationId':locationId}});
                this.$router.push({path:"/EquipmentLayoutView", query:{'locationId':locationId,'title':'园区设备分布图',}});
              }
            }).appendTo($("#textDivMz"));

            //提示框箭头
            $("<div/>",{
              id: "arrow-down"+locationId
            }).appendTo($("#textDivMz"));
            this.Drawdesigns(px,py,locationId,index,ChartData);
            if(this.expansion){//菜单展开
              $('#'+"mz"+locationId).css({
                position: "absolute",
                zIndex:4,
                cursor:"pointer",
                fontFamily: "Microsoft YaHei",
                top: py-convertImg(75),
                left: px-convertImg(160)
              });
              $('#'+"cover"+locationId).css({
                position: "absolute",
                zIndex:9,
                cursor:"pointer",
                fontFamily: "Microsoft YaHei",
                top: py-convertImg(75),
                left: px-convertImg(160)
              });
              $('#'+"arrow-down"+locationId).css({
                position: "absolute",
                zIndex:4,
                cursor:"pointer",
                fontFamily: "Microsoft YaHei",
                top: py-convertImg(33),
                left: px-convertImg(144),
                width:0,
                height:0,
                borderLeft:'6px solid transparent',
                borderRight:'6px solid transparent',
                borderTop:'6px solid #288cff',
              })
            }else{
              $('#'+"mz"+locationId).css({
                position: "absolute",
                zIndex:4,
                cursor:"pointer",
                fontFamily: "Microsoft YaHei",
                top: py-convertImg(75),
                left: px-convertImg(80)
              });
              $('#'+"cover"+locationId).css({
                position: "absolute",
                zIndex:9,
                cursor:"pointer",
                fontFamily: "Microsoft YaHei",
                top: py-convertImg(75),
                left: px-convertImg(80)
              });
              $('#'+"arrow-down"+locationId).css({
                position: "absolute",
                zIndex:4,
                cursor:"pointer",
                fontFamily: "Microsoft YaHei",
                top: py-convertImg(33),
                left: px-convertImg(65),
                width:0,
                height:0,
                borderLeft:'6px solid transparent',
                borderRight:'6px solid transparent',
                borderTop:'6px solid #288cff',
              })
            }
          }
        }
      },
    }
  }
</script>

<style scoped>
  .popup{
    position: absolute;
    width:300px;
    min-height:165px;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
    z-index: 5;
    border: 2px solid #18a054;
    background-color: rgba(2,108,69,0.5);
  }
  .list{
    margin-top: 10px;
  }
  .list p{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    font-size: 16px;
    color: #fff;
  }
  .list .state{
    display: inline-block;
    padding-left: 20px;
    font-size: 16px;
  }
  .list .state .normal{
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    vertical-align: middle;
    margin-right: 6px;
    background-color:#1dac5d;
  }
  .list .state .unusual{
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    vertical-align: middle;
    margin-right: 6px;
    background-color:#ff9600;
  }
  .list .state .notrunning{
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    vertical-align: middle;
    margin-right: 6px;
    background-color:#f14848;
  }
  .list .state .DeviceFault{
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    vertical-align: middle;
    margin-right: 6px;
    background-color:#ff715b;
  }
  .list .state .DeviceHalt{
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    vertical-align: middle;
    margin-right: 6px;
    background-color:#cccccc;
  }

  .canvas-expansion{
    position: absolute;
    top:0px;
    left:0px;
    width: 1603px;
    height:800px;
    /* background-color: #fff; */
    background-size: 1603px 800px;
    background-repeat: no-repeat;
    background-position:center;
    z-index: 1;
  }
  .canvas{
    position: absolute;
    top:0px;
    left:0px;
    width: 1800px;
    height:800px;
    /* background-color: #fff; */
    background-size: 1800px 800px;
    background-repeat: no-repeat;
    background-position:center;
    z-index: 1;
  }
  .canvas2-expansion{
    position: absolute;
    top:0px;
    left:0px;
    width: 1603px;
    height:800px;
    z-index: 2;
  }
  .canvas2{
    position: absolute;
    top:0px;
    left:0px;
    width: 1800px;
    height:800px;
    z-index: 2;
  }
  .canvas3-expansion{
    position: absolute;
    top:0px;
    left:0px;
    width: 1603px;
    height:800px;
    z-index: 4;
  }
  .canvas3{
    position: absolute;
    top:0px;
    left:0px;
    width: 1800px;
    height:800px;
    z-index: 4;
  }
  .textDivMz-expansion{
    position: absolute;
   /* top:36px;
    left:36px; */
    top: 0;
    left: 0;
    width: 1603px;
    height:800px;
    color: #ffffff !important;
  }
  .textDivMz{
    position: absolute;
    top: 0;
    left: 0;
   /* top:36px;
    left:36px; */
    width: 1800px;
    height:800px;
    color: #ffffff !important;
  }
  @media only screen and (max-aspect-ratio: 1920/920 ) {
    /**/
    .BackgroundBox{
      position: relative;
      top:0;
      left:0px;
      width: 1640px;
      height:750px;
      background-image: url('../../../static/images/BackgroundBg.png');
      background-size: 1640px 750px;
      background-repeat: no-repeat;
      background-position:center;
      z-index: 1;
      /* overflow: hidden; */
    }
    .BackgroundBox2{
      position: relative;
      top:0;
      left:0px;
      width: 1820px;
      height:750px;
      background-image: url('../../../static/images/BackgroundBg.png');
      background-size: 1820px 750px;
      background-repeat: no-repeat;
      background-position:center;
      z-index: 1;
      /* overflow: hidden; */
    }
    .ContainerDiv{
      position: relative;
      top: 20px;
      left: 20px;
      width: 1703px;
      height: 675px;
      border-radius: 4px;
      overflow: hidden;
    }
    .ContainerDiv2{
      position: relative;
      top: 20px;
      left: 10px;
      width: 1790px;
      height: 675px;
      border-radius: 4px;
      overflow: hidden;
    }

	  .header{
		width: 100%;
		height: 30px;
		line-height: 0px;
	  }
	  .header span:nth-child(1){
		font-size: 18px;
		color: #000000;
	  }
	  .header span:nth-child(2){
		font-size: 15px;
		color: #666;
		padding-left:15px;
	  }
    .Iconnavigation{
      width: 100%;
      height: 100%;
    }
    .listicon{
      width: 18.5%;
      height:100%;
      position: relative;
      display: inline-block;
    }
    .listicon img{
      width:58px;
      height: 44px;
      padding-top:12px;
      padding-left: 20px;
    }
    .listicon .test1{
      position: absolute;
      top: 10px;
      left: 77px;
      color: #ffffff;
      font-size: 22px;
      font-family: 'GemunuLibre-Regular';
    }
    .testcolor1{
      position: absolute;
      top: 10px;
      left: 75px;
      color: #ffffff;
      font-size: 22px;
      font-family: 'GemunuLibre-Regular';
    }
    .listicon .test2{
      position: absolute;
      top: 34px;
      font-size: 15px;
      color: #fff;
      left: 77px;
      white-space: nowrap;
    }
    .StateHead{
      position: absolute;
      top: 30px;
      left: 30px;
      width: 715px;
      height: 65px;
      background-image: url('../../../static/images/numericalBg.png');
      background-size: 715px 65px;
      z-index: 9;
    }
  }
  @media only screen and (min-aspect-ratio: 1920/920 ) {
    /**/
    .BackgroundBox{
      position: relative;
      top:0;
      left:0px;
      width: 1640px;
      height:79vh;
      background-image: url('../../../static/images/BackgroundBg.png');
      background-size: 1640px 79vh;
      background-repeat: no-repeat;
      background-position:center;
      z-index: 1;
      /* overflow: hidden; */
    }
    .BackgroundBox2{
      position: relative;
      top:0;
      left:0px;
      width: 1820px;
      height:79vh;
      background-image: url('../../../static/images/BackgroundBg.png');
      background-size: 1820px 79vh;
      background-repeat: no-repeat;
      background-position:center;
      z-index: 1;
      /* overflow: hidden; */
    }
    .ContainerDiv{
      position: relative;
      top: 20px;
      left: 20px;
      width: 1703px;
      height: 71vh;
      border-radius: 4px;
      overflow: hidden;
    }
    .ContainerDiv2{
      position: relative;
      top: 20px;
      left: 10px;
      width: 1790px;
      height: 71vh;
      border-radius: 4px;
      overflow: hidden;
    }

	.header{
		width: 100%;
		height: 3vh;
		line-height: 0px;
	}
	.header span:nth-child(1){
		font-size: 18px;
		color: #000000;
	}
	.header span:nth-child(2){
		font-size: 15px;
		color: #666;
		padding-left:15px;
	}
    .Iconnavigation{
      width: 100%;
      height: 100%;
    }
    .listicon{
      width: 18.5%;
      height:100%;
      position: relative;
      display: inline-block;
    }
    .listicon img{
      width:58px;
      height:4.4vh;
      padding-top:1.2vh;
      padding-left: 20px;
    }
    .listicon .test1{
      position: absolute;
      top: 1vh;
      left: 77px;
      color: #ffffff;
      font-size: 22px;
      font-family: 'GemunuLibre-Regular';
    }
    .testcolor1{
      position: absolute;
      top: 1vh;
      left: 75px;
      color: #ffffff;
      font-size: 22px;
      font-family: 'GemunuLibre-Regular';
    }
    .listicon .test2{
      position: absolute;
      top: 3.4vh;
      font-size: 15px;
      color: #fff;
      left: 77px;
      white-space: nowrap;
    }
    .StateHead{
      position: absolute;
      top: 3vh;
      left: 30px;
      width: 715px;
      height: 6.5vh;
      background-image: url('../../../static/images/numericalBg.png');
      background-size: 715px 6.5vh;
      z-index: 9;
    }
  }
</style>